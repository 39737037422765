import React from "react";

function Nft() {
  return (
    <main>
      Non-fungible tokens.{" "}
      <span className="text-grey">
        I have been working on some NFTs and will be uploading them soon.
      </span>
    </main>
  );
}

export default Nft;
