import React from 'react'

function Resume() {
    return (
        <h1>
            Resume Page!
        </h1>
    )
}

export default Resume
